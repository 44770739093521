@import '../../../../scss/theme-bootstrap';

// Formatter styles.
.gnav-header-formatter {
  &__wrapper,
  .header-black-white {
    @include gnav-offer-bar-color($cr19-bg-black, $cr19-text-white, $cr19-text-inactive);
    @include gnav-navigation-bar-color($cr19-bg-white, $color-cl-light-black);
  }
  .header-black-light-transparent {
    @include gnav-offer-bar-color($cr19-bg-black, $cr19-text-white, $cr19-text-inactive);
    @include gnav-navigation-bar-color($cr19-bg-light-transparent, $color-cl-light-black);
  }
  .header-black-dark-transparent {
    @include gnav-offer-bar-color($cr19-bg-black, $cr19-text-white, $cr19-text-inactive);
    @include gnav-navigation-bar-color($cr19-bg-dark-transparent, $cr19-text-white);
  }
  .header-white-light-transparent {
    @include gnav-offer-bar-color($cr19-bg-white, $cr19-text, $cr19-text-inactive);
    @include gnav-navigation-bar-color($cr19-bg-light-transparent, $cr19-text);
  }
  .header-white-dark-transparent {
    @include gnav-offer-bar-color($cr19-bg-white, $cr19-text, $cr19-text-inactive);
    @include gnav-navigation-bar-color($cr19-bg-dark-transparent, $cr19-text);
  }
  .header-white-white {
    @include gnav-offer-bar-color($cr19-bg-white, $cr19-text, $cr19-text-inactive);
    @include gnav-navigation-bar-color($cr19-bg-white, $cr19-text);
  }
  &__bg-full-width {
    background-color: $cr19-bg-dark-transparent;
    visibility: hidden;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: $fixed-z-index - 1;
    #{$ldirection}: 0;
    top: 0;
    height: 100%;
    transition: visibility 0.7s, opacity 0.7s; // @todo standardize this across panels
    .active-gnav & {
      @media #{$cr19-large-up} {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &__wrapper {
    /* sticky */
    .sticky-nav-active & {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: $fixed-z-index;
      .gnav-header-formatter__top {
        display: none;
      }
    }
    // Unstick sticky nav when panel is open
    .sticky-nav-active.active-gnav &,
    .sticky-nav-active.product-shades-sidebar-active & {
      position: static;
    }
  }
  &__top {
    width: 100%;
  }
  &__gnav,
  &__gnav-content {
    min-height: $navbar-sm;
    @media #{$cr19-large-up} {
      min-height: $navbar-lg;
    }
    .active-gnav & {
      overflow: hidden;
    }
  }
  &__logo {
    align-items: center;
    position: relative;
    .search-overlay-displayed & {
      @media #{$cr19-large-up} {
        top: 0;
        z-index: auto;
      }
      top: -40px;
      z-index: $fixed-z-index + 5;
    }
    > a {
      display: inline-block;
    }
    .active-gnav & {
      // Styles here are for exposing the logo for mobile when the full panel is open
      @include swap_direction(padding, 24px 0 24px 20px);
      animation: mobile-panel-logo 1s;
      animation-fill-mode: forwards;
      background-color: $cr19-bg-white;
      position: fixed;
      #{$ldirection}: 0;
      min-height: $navbar-sm;
      top: 0;
      width: calc(100% - 37px);
      z-index: $fixed-z-index + 5;
      @media #{$cr19-large-up} {
        animation: none;
        background-color: transparent;
        min-height: 30px;
        padding: 0;
        position: relative;
        top: auto;
        width: auto;
        z-index: 0;
      }
    }
  }
  &__gnav-util {
    justify-content: flex-end;
    // Update contextual links placement in the header so it does not block our icons on scroll
    .block .contextual-links-container {
      top: -24px;
      div.contextual-links-wrapper {
        .active-gnav & {
          z-index: $fixed-z-index - 2;
        }
      }
    }
  }
  &__top-content,
  &__gnav-content,
  &__gnav-util {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  html[dir='rtl'] &,
  body.rtl & {
    &__gnav-content,
    &__gnav-util {
      flex-direction: row-reverse;
    }
  }
  &__top-content {
    justify-content: space-between;
  }
  &__gnav-util {
    flex: 1;
    flex-basis: auto;
  }
  &__offer {
    flex: 1;
    overflow: hidden;
  }
  &__top-account {
    display: none;
    @media #{$cr19-large-up} {
      display: block;
    }
  }
  &__language-toggle {
    margin-top: 6px;
    a {
      @include text-link--style-2;
    }
  }
  &__top-language-toggle {
    display: none;
    @media #{$cr19-large-up} {
      display: block;
      margin-#{$ldirection}: 40px;
    }
    .footer-language-toggle {
      a {
        @include text-link--style-6;
        .header-black-white &,
        .header-black-light-transparent & .header-black-dark-transparent & {
          @include text-link--style-6;
        }
        .header-white-white &,
        .header-white-light-transparent & .header-white-dark-transparent & {
          @include text-link--style-2;
        }
      }
    }
  }
  &__hamburger--mobile {
    display: none;
    // no need to use left and right variables for RTL sites for these gnav elements
    margin-left: 26px;
    &.visible {
      display: block;
      @media #{$cr19-large-up-landscape} {
        display: none;
      }
    }
  }
  &__hamburger--pc,
  &__sections--pc {
    display: none;
    @media #{$cr19-large-up-landscape} {
      display: block;
    }
  }
  &__sections--pc {
    margin-#{$ldirection}: 10px;
    .header-gnav-section__link,
    .header-gnav-section__label {
      @media #{$cr19-large-up} {
        display: inline-block;
        margin: 0 6px;
        position: relative;
      }
      @media #{$cr19-large-up-landscape} {
        margin: 0 12px;
      }
      @media #{$cr19-xlarge-up} {
        margin: 0 15px;
      }
    }
  }
  .svgicon {
    margin: auto;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    &--logo-clinique {
      height: 30px;
      width: 110px;
      @media #{$cr19-large-up} {
        height: 45px;
        width: 155px;
      }
    }
    &--x-16 {
      width: 17px;
      height: 17px;
    }
    &--arrow-8 {
      width: 8px;
      height: 4px;
      margin-#{$ldirection}: 12px;
    }
  }
  &__sections-panel {
    padding: 78px 20px 100px; // top pad for logo
    background: $color-white;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: fixed;
    top: 0;
    width: 100%;
    overflow: hidden;
    height: 100%;
    z-index: $fixed-z-index;
    // no need to use left and right variables for RTL sites, the section is to be displayed to the right
    float: right;
    right: -100%;
    transition: right 0.7s ease-in-out, width 0.7s ease-in-out; // @todo standardize this across panels
    visibility: hidden;
    @media #{$cr19-large-up} {
      @include swap_direction(padding, 20px 20px 0 24px);
      width: 364px;
      &.expanded {
        width: 664px;
      }
    }
    &.visible {
      position: fixed;
      right: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      overflow-y: auto;
      z-index: $fixed-z-index + 4;
      visibility: visible;
      &:not(.expanded) {
        .header-gnav-account-link__section {
          margin-bottom: 3px;
          .header-gnav-account-link__label-wrapper {
            .header-gnav-account-link__text {
              font-family: $helvetica-regular-font-family;
              font-size: 15px;
            }
          }
        }
      }
    }
    .header-gnav-section__link {
      &::after {
        display: none;
      }
      &.active {
        color: $cr19-text;
        &:focus {
          outline: none; // text is bolded we do not need outnine for ADA
        }
      }
      &--hidden {
        display: none;
      }
    }
    .header-gnav-section {
      &.disabled {
        .header-gnav-section__link,
        .header-gnav-section__label {
          color: $cr19-text-grey;
        }
      }
      &.current {
        .header-gnav-section__link,
        .header-gnav-section__label {
          color: $cr19-text;
        }
      }
      &.header-gnav-account-link__section {
        &.disabled {
          display: none;
        }
        &.pc-hidden {
          @media #{$medium-up} {
            display: none;
          }
        }
      }
    }
    .show-subpanel {
      .header-gnav-section {
        &.disabled {
          .header-gnav-section__link,
          .header-gnav-section__label {
            color: $cr19-text-grey;
            &.active {
              color: $cr19-text;
            }
          }
        }
        &.current {
          .header-gnav-section__link,
          .header-gnav-section__label {
            color: $cr19-text;
          }
        }
      }
      [aria-expanded='false'] {
        .header-gnav-section__link,
        .header-gnav-section__label {
          color: $cr19-text-grey;
        }
      }
      [aria-expanded='true'] {
        .header-gnav-section__link,
        .header-gnav-section__label {
          color: $cr19-text;
          &:focus {
            outline: none;
          }
        }
      }
    }
    .header-gnav-link__chat {
      cursor: pointer;
    }
    .gnav-header-formatter__bottom {
      .lang-abbr {
        display: none;
      }
      .lang-full {
        display: inline-block;
      }
    }
  }
  body.device-mobile & {
    .gnav-header-formatter__sections-panel {
      &.visible.expanded {
        .header-gnav-account-link__wrapper--with-content {
          .header-gnav-account-link__section[aria-expanded='false'] {
            .header-gnav-account-link__text {
              font-family: $helvetica-regular-font-family;
              font-size: 15px;
            }
          }
          .header-gnav-account-link__panel-wrapper {
            .header-gnav-links {
              padding-bottom: 10px;
              margin-bottom: 14px;
              border-bottom: $cr19-border-light solid 1px;
            }
          }
          &.gnav-account-link-enable {
            border-top: none;
            margin-top: 0;
            padding-top: 0;
          }
        }
      }
    }
  }
  // no need to use left and right variables for RTL sites for these elements
  &__search {
    // margin is less because of the bag width
    background: url(/sites/clinique/themes/cl_base/img/icons/src/search-icon.svg) 0 center no-repeat;
    margin-left: 16px;
    // @todo CONNAVFO-1952 we need to figure out exactly why adding text to the search field alters the position of the icon.  This patch works in the meantime
    width: 24px;
    height: 24px;
    position: relative;
    @media #{$cr19-large-up} {
      margin-left: 20px;
    }
  }
  &__bag {
    margin-left: 36px;
    width: 34px; // set a width that allows the cart count to be positioned
  }
  &__hamburger--pc {
    @media #{$cr19-large-up-landscape} {
      margin-#{$ldirection}: 12px;
      display: flex;
      white-space: nowrap;
      .text-link--style-2 {
        text-transform: inherit;
      }
    }
    @media #{$cr19-xlarge-up} {
      margin-#{$ldirection}: 24px;
    }
  }
  &__hamburger,
  &__close {
    cursor: pointer;
  }
  &__links {
    flex-basis: auto;
    transition: color 0.3s linear;
    @media #{$cr19-medium-up} {
      flex: 1;
    }
    @media #{$cr19-large-up} {
      position: relative;
    }
  }
  &__bottom {
    padding-bottom: 20px;
    @media #{$cr19-large-up} {
      bottom: 0;
      position: relative;
      width: 310px;
    }
    &-links {
      display: flex;
      flex-direction: column;
    }
    &-link {
      margin-bottom: 13px;
      @media #{$cr19-large-up} {
        margin-bottom: 9px;
      }
    }
    .header-gnav-link__chat {
      display: inline-block;
      margin-bottom: 13px;
      @media #{$cr19-large-up} {
        margin-bottom: 9px;
      }
    }
  }
  &__close {
    display: none;
    .gnav-header-formatter__sections-panel.visible & {
      @include swap_direction(padding, 24px 20px 27px 0);
      background-color: $cr19-bg-white;
      display: block;
      position: fixed;
      #{$rdirection}: 0;
      z-index: $fixed-z-index + 5;
      top: 0;
      @media #{$cr19-large-up} {
        @include swap_direction(padding, 20px 20px 0 0);
        position: absolute;
      }
    }
    .gnav-header-formatter__close-icon {
      margin: 0;
    }
  }
  .header-gnav-section__panel {
    @media #{$cr19-large-up} {
      position: absolute;
      top: 0;
      #{$ldirection}: 50%;
      width: 50%;
      overflow-y: auto;
      height: 100%;
      padding-bottom: $navbar-sm;
      padding-#{$ldirection}: 20px;
      transition: width 0.7s ease-in-out; // @todo standardize this across panels
    }
  }
  /* showing submenu. */
  .header-gnav-section__panel-wrapper {
    height: 0;
    opacity: 0;
    visibility: hidden;
    position: relative;
    transition: all 0.7s ease-in-out; // @todo standardize this across panels
    overflow: hidden;
    @media #{$cr19-medium-up} {
      overflow: auto;
    }
    @media #{$cr19-large-up} {
      position: unset;
    }
  }
  .header-gnav-section__link-wrapper {
    @media #{$cr19-large-up} {
      width: 310px;
    }
  }
  [aria-expanded='true'] {
    .header-gnav-section__panel-wrapper {
      height: auto;
      opacity: 1;
      visibility: visible;
      position: relative;
      @media #{$cr19-large-up} {
        position: unset;
      }
    }
    .header-gnav-section__link-wrapper,
    .header-gnav-section__panel-wrapper {
      @media #{$cr19-large-up} {
        width: 350px; // @todo use percentage
        height: 100%;
        position: static;
      }
    }
    .header-gnav-section__link-wrapper,
    .header-gnav-section__label-wrapper {
      margin-bottom: 35px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      @media #{$cr19-large-up} {
        margin-bottom: 13px;
      }
    }
    // @todo CONNAVFO-1865 remove backArrow when new section fields are in place
    .header-gnav-section__back-arrow--mobile {
      cursor: pointer;
      display: block;
      margin: 0;
      transform: rotate(180deg);
      margin-#{$rdirection}: 20px;
      @media #{$cr19-large-up} {
        display: none;
      }
    }
    .header-gnav-section__label {
      padding-#{$ldirection}: 44px;
      &:before {
        background: $color-white url('/sites/clinique/themes/cl_base/img/icons/src/arrow-16.svg') 0
          0 no-repeat;
        content: '';
        height: 24px;
        #{$ldirection}: 0;
        position: absolute;
        transform: rotate(180deg);
        width: 24px;
      }
      @media #{$cr19-large-up} {
        padding-#{$ldirection}: 0;
        &:before {
          display: none;
        }
      }
    }
  }
  .show-subpanel {
    [aria-expanded='false'] {
      .header-gnav-section__link-wrapper,
      .header-gnav-section__label-wrapper {
        display: none;
        @media #{$cr19-large-up} {
          display: block;
        }
      }
    }
  }
  .gnav-header-utility-icon-link {
    display: inline-block;
    height: 24px;
    line-height: 1;
    overflow: hidden;
  }
}

// Overrides for specific sections and states applied to body
body {
  &.active-gnav,
  &.product-shades-sidebar-active {
    overflow: hidden; // No scrolling the body when fixed nav panels are active
    &.sticky-nav-active #toolbar {
      z-index: $fixed-z-index - 2;
    }
  }
  &.sticky-nav-active {
    #toolbar {
      z-index: $fixed-z-index + 2;
    }
  }
  // GNAV simplified on all checkout pages, excluding the cart page.
  &#samples,
  &#index.active-panel-shipping,
  &#index.active-panel-payment,
  &#index.opc__shipping,
  &#index.opc__review,
  &#shipping,
  &#billing,
  &#payment,
  &#payment_select,
  &#review,
  &#confirm,
  &#index.checkout.elc-user-state-logged-in {
    .gnav-header-formatter {
      &__top,
      &__gnav-util {
        display: none;
      }
    }
    &.sticky-nav-active {
      .gnav-header-formatter__wrapper {
        top: 0;
      }
    }
  }
  &#index {
    &.checkout {
      &.active-panel-registration {
        .gnav-header-formatter {
          &__top,
          &__gnav-util {
            display: none;
          }
        }
        &.sticky-nav-active {
          .gnav-header-formatter__wrapper {
            top: 0;
          }
        }
      }
    }
  }
}

// Animation for exposing the logo in the mobile panel.
@keyframes mobile-panel-logo {
  0% {
    opacity: 0;
    visibility: none;
  }
  80% {
    opacity: 0.5;
    visibility: visible;
  }
  100% {
    opacity: 1;
  }
}
